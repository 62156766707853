import {Injectable} from "@angular/core";
import {OAuthService, UserInfo} from "angular-oauth2-oidc";
import {BehaviorSubject, Subject} from "rxjs";
import {filter, takeUntil} from "rxjs/operators";
import {ConfigService, IAuthConfig} from "@app/shared/services/config.service";
import {UserInfoDto} from "@app/shared/dto/user/user-info.dto";
import convert from "lodash/fp/convert";
import {UserService} from "@app/shared/services/user/user.service";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private userInfoPromise = new BehaviorSubject<UserInfo>(undefined);
  private userInfo: UserInfoDto;
  private authConfig: IAuthConfig;

  constructor(private oauthService: OAuthService,
              private userService: UserService,
              private configService: ConfigService) {

    let isLoadingUserProfile = false;
    this.oauthService.events.pipe(
        filter((event) => ["discovery_document_loaded", "token_refreshed"].indexOf(event.type) > -1),
    ).subscribe((event) => {
      if (event.type === "discovery_document_loaded") {
        this.oauthService.userinfoEndpoint = this.authConfig.userinfoEndpoint;
      }
      if (this.oauthService.hasValidAccessToken() && !isLoadingUserProfile) {
        isLoadingUserProfile = true;
        this.oauthService.loadUserProfile().then((info: UserInfo) => {
          this.userInfo = this.convert(info);
          this.userInfoPromise.next(this.userInfo);
        }).finally(() => {
          isLoadingUserProfile = false;
        })
      }
    })
  }

  configure(): void {
    this.authConfig = this.configService.getConfig().auth;
    this.oauthService.configure({
      requireHttps: this.authConfig.requireHttps,
      issuer: this.authConfig.issuer,
      clientId: this.authConfig.clientId,
      redirectUri: this.authConfig.callbackUrl,
      logoutUrl: this.authConfig.logOutUrl,
      scope: this.authConfig.scope,
      showDebugInformation: false,
      strictDiscoveryDocumentValidation: false,
      responseType: this.authConfig.responseType,
      oidc: true,
      requestAccessToken: true,
      useSilentRefresh: true,

    });

    this.oauthService.loadDiscoveryDocument().then();

  }

  getUserInfo(): UserInfoDto {
    return this.userInfo;
  }

  getUserInfoPromise(): Promise<UserInfoDto> {
    const stop = new Subject<boolean>();
    return new Promise<UserInfoDto>((resolve, reject) => {
      this.userInfoPromise.pipe(
          takeUntil(stop)
      ).subscribe((info) => {
        if (info) {
          stop.next(true);
          resolve(convert(info));
        }
      })
    });
  }

  convert(info: UserInfo): UserInfoDto {
    const userInfo = new UserInfoDto();
    userInfo.id = info['id'];
    userInfo.sub = info.sub;
    userInfo.status = info['status'];
    userInfo.email = info['email'];
    userInfo.given_name = info['given_name'];
    userInfo.family_name = info['family_name'];
    userInfo.customer_ids = info['customer_ids'];
    userInfo.active_customer_id = info['active_customer_id'];
    userInfo.permissions = info['permissions'];

    return userInfo;
  }

  isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  logIn(redirectUrl?): void {
    if (redirectUrl) {
      sessionStorage.setItem("redirectUrl", redirectUrl);
    }

    return this.oauthService.initCodeFlow();
  }

  logOut(globally = false): void {
    if (globally) {
      this.userService.signOutGlobally().subscribe(() => {
        this.oauthService.logOut();
      });
    } else {
      this.oauthService.logOut();
    }
  }

  idToken(): string {
    return this.oauthService.getIdToken();
  }
}
