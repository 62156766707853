import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

export interface IAuthConfig {
  requireHttps: boolean;
  userinfoEndpoint: string,
  issuer: string;
  clientId: string;
  responseType: string;
  scope: string;
  callbackUrl: string;
  logOutUrl: string;
}

export interface IConfig {
  auth: IAuthConfig,
  envName: string,
  dataPortalHostname?: string
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config: IConfig;
  private configPromise: Promise<boolean>;

  constructor(private http: HttpClient) {
  }

  public getConfig(): IConfig{
    return this.config;
  }

  public getConfigPromise(): Promise<boolean> {
    return this.configPromise;
  }

  /**
   * This method:
   *  a) loads oauth2-config.json for the current working environment
   *  b) configures amplify
   */
  public init(): Promise<boolean> {
    this.configPromise = new Promise(((resolve, reject) => {
      this.http.get("/public/config.json").subscribe((config: IConfig) => {
        this.config = config;
        resolve(true);
      })
    }));
    return this.configPromise;
  }
}
