import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ToolQueryDto} from "@app/shared/dto/tool/tool.query.dto";
import {ToolDto} from "@app/shared/dto/tool/tool.dto";
import {ToolUsageReportDto} from "@app/shared/dto/tool/tool-usage-report.dto";
import {Observable} from "rxjs";
import {ToolUsageExportDto} from "@app/shared/dto/tool/tool-usage-export.dto";
import {ToolUsageQueryDto} from "@app/shared/dto/tool/tool-usage-query.dto";

@Injectable()
export class ToolUsageService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = '/api/tool-usages';
  }

  findAll(query: ToolQueryDto): Observable<ToolDto[]> {
    return this.http.post<ToolDto[]>(this.baseUrl, query);
  }

  report(toolId: string, toolUsageReport: ToolUsageReportDto): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${toolId}/report`, toolUsageReport);
  }

  findAllUsages(toolUsageQueryDto: ToolUsageQueryDto): Observable<ToolUsageExportDto[]> {
    return this.http.post<ToolUsageExportDto[]>(`${this.baseUrl}/`, toolUsageQueryDto);
  }

}
